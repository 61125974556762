import "./styles.scss"

import React from "react"
import ZoomableMap from "components/ZoomableMap"

const Map = () => {
  return (
    <section className="location-map">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="location-map__content">
              <p>
                Wybierając City Łobzów, wybierasz bycie w centrum wydarzeń. Tych
                małych, lokalnych, tych większych w sercu Krakowa, ale i tych
                największych, bo z City Łobzów jesteś o krok od całej Polski,
                Europy i świata. Komunikacja miejska, dworzec kolejowy i
                autobusowy, lotnisko – w ciągu paru chwil możesz ruszyć na
                miasto, w Polskę lub na przygodę życia! Bo stąd masz wszędzie
                blisko!
              </p>
              <p>
                <strong>
                  City Łobzów przy ul. Głowackiego w Krakowie to inwestycja
                  <br />
                  dedykowana wszystkim tym, którzy żyją w rytmie miasta.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="location-map__item">
        <ZoomableMap />
        {/* <div
              className="location-map__image"
              style={{
                backgroundImage: `url(${
                  require("assets/images/location/lobzow-map.png").default
                })`,
              }}
            /> */}
      </div>
      {/* <div className="location-map__legend">
        <div className="container-fluid">
          <div className="row">
            {data?.map((item, index) => (
              <div key={index} className="col-lg-3 col-md-4 col-6">
                <div className="location-map__legend-item">
                  <div
                    className="location-map__legend-item-image"
                    style={{ backgroundColor: item.color }}
                  >
                    <img src={item.image} alt="" />
                  </div>
                  <p>{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </section>
  )
}

// const data = [
//   {
//     image: require("assets/images/location/legend-ico-1.svg").default,
//     label: (
//       <>
//         Przystanek
//         <br />
//         autobusowy
//       </>
//     ),
//     color: "#3C8095",
//   },
//   {
//     image: require("assets/images/location/legend-ico-2.svg").default,
//     label: (
//       <>
//         Przystanek
//         <br />
//         tramwajowy
//       </>
//     ),
//     color: "#07956E",
//   },
//   {
//     image: require("assets/images/location/legend-ico-3.svg").default,
//     label: (
//       <>
//         Przystanek
//         <br />
//         kolejowy
//       </>
//     ),
//     color: "#CCBF25",
//   },
//   {
//     image: require("assets/images/location/legend-ico-4.svg").default,
//     label: (
//       <>
//         Uniwersytet
//         <br />
//         Pedagogiczny
//       </>
//     ),
//     color: "#402963",
//   },
//   {
//     image: require("assets/images/location/legend-ico-5.svg").default,
//     label: "Sklepy spożywcze",
//     color: "#6C3FB0",
//   },
//   {
//     image: require("assets/images/location/legend-ico-6.svg").default,
//     label: "WKS Wawel",
//     color: "#A53737",
//   },
//   {
//     image: require("assets/images/location/legend-ico-7.svg").default,
//     label: "Strzelnica",
//     color: "#634724",
//   },
//   {
//     image: require("assets/images/location/legend-ico-8.svg").default,
//     label: "Jednostka wojskowa",
//     color: "#525252",
//   },
//   {
//     image: require("assets/images/location/legend-ico-9.svg").default,
//     label: "Park Fort Bronowice",
//     color: "#3FA536",
//   },
//   {
//     image: require("assets/images/location/legend-ico-10.svg").default,
//     label: "Park Młynówka Królewska",
//     color: "#176A10",
//   },
// ]

export default Map
